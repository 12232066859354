import React from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import PropTypes from "prop-types"
import { commonHelper } from "helpers"

const getCurrencySymbol = currency =>
  commonHelper.currency.getCurrency(currency)?.symbol

const Overhead = ({ name }) => {
  return (
    <div className="pricing-block__card-overhead">
      <span className="plans-name">{name}</span>
      <svg width="18" height="9" className="pricing-block__card-overhead-icon">
        <svg
          id="card_arrow"
          viewBox="0 0 18 9"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9.68674 8.3512C9.30132 8.71533 8.69868 8.71533 8.31326 8.3512L1.3016 1.7269C0.644001 1.10563 1.08368 0 1.98835 0L16.0117 0C16.9163 0 17.356 1.10563 16.6984 1.7269L9.68674 8.3512Z" />
        </svg>
      </svg>
    </div>
  )
}

Overhead.propTypes = {
  name: PropTypes.string,
}

const Wrapper = ({ children }) => {
  return <div className="pricing-block__card-wrapper">{children}</div>
}

Wrapper.propTypes = {
  children: PropTypes.any,
}

const ContentBox = ({ children }) => {
  return <div className="pricing-block__card-content-box">{children}</div>
}

ContentBox.propTypes = {
  children: PropTypes.any,
}

const Pricing = ({ price, vat_text, isAnnual, bonus_text }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <div className="pricing-block__card-price">
        <span className="currency">{getCurrencySymbol(price.currency)}</span>
        <span className="price">{price.value}</span>
      </div>
      {vat_text && (
        <div className="pricing-block__card-price-desc">{vat_text}</div>
      )}
      <div className="pricing-block__card-billing">
        {isAnnual ? t("billed_annually") : t("billed_monthly")}
      </div>
      {/*<a href="#" className="pricing-block__card-free-block">Try now for FREE</a>*/}
      {bonus_text && bonus_text !== "" && (
        <div
          className="pricing-block__card-bonus"
          dangerouslySetInnerHTML={{ __html: bonus_text }}
        />
      )}
    </Wrapper>
  )
}

Pricing.propTypes = {
  price: PropTypes.shape({
    currency: PropTypes.string,
    value: PropTypes.string,
  }),
  vat_text: PropTypes.string,
  isAnnual: PropTypes.bool,
  bonus_text: PropTypes.string,
}

const Benefits = ({ benefits_text }) => {
  return (
    <Wrapper>
      <div
        className="pricing-block__card-list"
        dangerouslySetInnerHTML={{ __html: benefits_text }}
      />
    </Wrapper>
  )
}

Benefits.propTypes = {
  benefits_text: PropTypes.string,
}

const Modules = ({ modules_text }) => {
  const { t } = useTranslation()

  return (
    <div className="pricing-block__card-bottom">
      <div className="pricing-block__card-inner-title">{t("modules")}</div>
      <div
        className="pricing-block__card-list"
        dangerouslySetInnerHTML={{ __html: modules_text }}
      />
    </div>
  )
}

Modules.propTypes = {
  modules_text: PropTypes.string,
}

const ActionButton = ({ onClick, disabled, label }) => {
  return (
    <a
      href="#"
      onClick={e => {
        e.preventDefault()
        onClick?.()
      }}
      className={classNames("tariff-btn tariff-btn--select", {
        "tariff-btn--select_disabled": disabled,
      })}
    >
      <svg width="16" height="16" className="selected">
        <svg
          id="selected"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill="#fff" />
          <path
            d="M4.8 8.693L7.115 10.4 11.2 4.8"
            strokeWidth="1.7"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </svg>

      {label}
    </a>
  )
}

ActionButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.any,
}

export const TariffCardContent = ({
  overhead,
  pricing,
  benefits,
  modules,
  actionButton,
}) => {
  return (
    <>
      {overhead}

      {pricing}

      {benefits}

      {modules}

      {actionButton}
    </>
  )
}

TariffCardContent.propTypes = {
  overhead: PropTypes.element,
  pricing: PropTypes.element,
  benefits: PropTypes.element,
  modules: PropTypes.element,
  actionButton: PropTypes.element,
}

TariffCardContent.Overhead = Overhead
TariffCardContent.Wrapper = Wrapper
TariffCardContent.ContentBox = ContentBox
TariffCardContent.Pricing = Pricing
TariffCardContent.Benefits = Benefits
TariffCardContent.Modules = Modules
TariffCardContent.ActionButton = ActionButton

export const TariffCardShell = ({ className, onClick, active, children }) => {
  return (
    <div
      className={classNames("pricing-block__card-block", {
        [className]: className,
        active: active,
      })}
      onClick={() => onClick?.()}
    >
      {children}
    </div>
  )
}

TariffCardShell.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  children: PropTypes.any,
}
