import React from "react"
import s from "./AiChatAffix.module.scss"
import { useSelector } from "react-redux"
import { AiChat } from "features/ai-chat/AiChat/components/AiChat"

export const AiChatAffix = () => {
  const { data: user } = useSelector(state => state.user.profile)

  return (
    <>
      {user?.has_ai_chat_access ? (
        <div className={s.root}>
          <AiChat url={user?.ai_chat_url} />
        </div>
      ) : null}
    </>
  )
}

AiChatAffix.propTypes = {}
