import React from "react"
import PropTypes from "prop-types"

import { OtherEvents } from "./OtherEvents"
import { useEventsCalendarContext } from "contexts"
import { ComparePeriod } from "./ComparePeriod"

export const CalendarActionBar = props => {
  const {
    config: { withComparePeriod },
  } = useEventsCalendarContext()

  return (
    <div className={"calendar-action-bar gap-4 flex-wrap"}>
      {withComparePeriod ? <ComparePeriod /> : null}

      <div
        className={
          "d-flex gap-4 align-items-center flex-wrap justify-content-end flex-grow-1"
        }
      >
        <OtherEvents onSelect={props.onEventSelect} />
      </div>
    </div>
  )
}

CalendarActionBar.propTypes = {
  onEventSelect: PropTypes.func,
}
