export const useAppIntroTourEffect = ({ alias, enabled, onStart }) => {
  // const dispatch = useDispatch()
  //
  // const tour = useSelector(state => selectAppIntroDataItem(state, alias))
  //
  // // start product tour if tour not viewed yet
  // useEffect(() => {
  //   if (tour?.viewed === false) {
  //     onStart()
  //   }
  // }, [tour?.viewed])
  //
  // // mark tour view if tour enabled and not viewed yet
  // useEffect(() => {
  //   return () => {
  //     if (enabled && tour?.viewed === false) {
  //       dispatch(appIntroData.setTourViewed({ alias }))
  //     }
  //   }
  // }, [enabled, tour?.viewed])
}
