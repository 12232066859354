import React, { useState } from "react"
import PropTypes from "prop-types"
import { useMountedState } from "react-use"
import { useTranslation } from "react-i18next"

import { useAppIntroTourEffect, usePanelLayoutPageIntroEffect } from "hooks"
import { VideoModal } from "components"
import { APP_INTRO_TOUR_ALIAS } from "consts"

export const PageIntro = props => {
  const { t } = useTranslation()
  const isMounted = useMountedState()

  const [enabled, setEnabled] = useState(false)

  const onIntroStart = () => {
    setEnabled(true)
  }

  const onIntroExit = () => {
    if (isMounted()) {
      setEnabled(false)
    }
  }

  usePanelLayoutPageIntroEffect({
    options: { visible: true, onStart: onIntroStart, label: "training_video_hub" },
  })

  useAppIntroTourEffect({
    alias: APP_INTRO_TOUR_ALIAS.CALENDAR,
    enabled,
    onStart: onIntroStart,
  })

  return (
    <>
      <VideoModal
        visible={enabled}
        onClose={onIntroExit}
        channel={"custom"}
        url={"https://www.youtube.com/embed/-zgdPg4Ad6Y?autoplay=0"}
      />

      {props.children}
    </>
  )
}

PageIntro.propTypes = {
  children: PropTypes.any,
}
