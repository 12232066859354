import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Redirect, Route, Switch, withRouter } from "react-router-dom"
import { AiChatAffix } from "features/ai-chat/AiChat/components/AiChatAffix"

import {
  LoadingScreen,
  ModalNotificationsEcho,
  PanelLayout,
  SocketMiddleware,
} from "components"
import { directorRoutes, employeeRoutes } from "routes/allRoutes"
import { ProtectedRoute } from "routes/middleware"
import { ErrorCard } from "./components"
import { panelGeneral, panelLoader } from "store/actions"
import { ROLE_DIRECTOR, ROLE_EMPLOYEE } from "consts"
import { EchoProvider } from "contexts"
import { useFacebookAuth } from "packages/facebook"

const PanelComponent = props => {
  const dispatch = useDispatch()

  const { checkFacebookLoginStatus, loading: isFacebookStatusLoading } =
    useFacebookAuth()

  useEffect(() => {
    dispatch(panelLoader.loadData({}))

    checkFacebookLoginStatus()

    return () => {
      dispatch(panelGeneral.resetState({}))
    }
  }, [])

  const renderRoutesByRole = () => {
    let routes = []
    switch (props.user?.role) {
      case ROLE_DIRECTOR:
        routes = directorRoutes
        break

      case ROLE_EMPLOYEE:
        routes = employeeRoutes
        break

      default:
        break
    }
    return routes.map((route, idx) => (
      <ProtectedRoute
        {...route}
        path={route.path}
        key={idx}
        middlewares={route.middlewares}
        component={route.component}
      />
    ))
  }

  return (
    <>
      {props.loading ? (
        <LoadingScreen loading={props.loading || isFacebookStatusLoading} />
      ) : props.prepared ? (
        <EchoProvider>
          <SocketMiddleware>
            <PanelLayout>
              <ModalNotificationsEcho />

              <Switch>
                <Route exact path={"/"}>
                  <Redirect to={`/calendar`} />
                </Route>

                {renderRoutesByRole()}
              </Switch>

              <AiChatAffix />
            </PanelLayout>
          </SocketMiddleware>
        </EchoProvider>
      ) : props.error ? (
        <ErrorCard error={props.error} />
      ) : null}
    </>
  )
}

PanelComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.any,
  prepared: PropTypes.bool,
  user: PropTypes.object,

  fetchUser: PropTypes.func,
  loadUserCleanState: PropTypes.func,
  packageDraftResetData: PropTypes.func,
  modelPanelModelsSidebarListCleanState: PropTypes.func,
  fetchOptions: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, error, prepared } = state.panel.loader

  const { data } = state.user.profile
  return {
    loading,
    error,
    prepared,
    user: data,
  }
}

const mapDispatchToProps = {}

export const Panel = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PanelComponent)
)
