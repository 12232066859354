import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import PropTypes from "prop-types"
import _ from "lodash"
import { TariffCardShell, TariffCardContent } from "../TariffCardShell"

import {
  BILLING_SUBSCRIPTION_TARIFF_ALIAS_BASIC,
  BILLING_SUBSCRIPTION_TARIFF_ALIAS_BUSINESS,
} from "consts"
import { billingHelper } from "helpers"

const billingCurrency = billingHelper.currency.getCurrency().value

export const TariffCard = props => {
  const { t } = useTranslation()

  const price = useMemo(() => {
    let item = _.find(props.data?.prices_monthly, { currency: props.currency })

    if (!item) {
      item = {
        currency: billingCurrency,
        value: props.data?.price_monthly,
      }
    }

    return item
  }, [props.data, props.currency])

  return (
    <TariffCardShell
      className={classNames("", {
        small: props.data?.alias === BILLING_SUBSCRIPTION_TARIFF_ALIAS_BASIC,
        medium:
          props.data?.alias === BILLING_SUBSCRIPTION_TARIFF_ALIAS_BUSINESS,
      })}
      onClick={() => props.onSelect(props.data)}
      active={props.selected || props.current}
    >
      <TariffCardContent
        overhead={<TariffCardContent.Overhead name={props.data?.name} />}
        pricing={
          <TariffCardContent.Pricing
            price={price}
            vat_text={props.data?.vat_text}
            bonus_text={props.data?.bonus_text}
            isAnnual={props.isAnnual}
          />
        }
        benefits={
          props.data?.benefits_text && props.data?.benefits_text !== "" ? (
            <TariffCardContent.Benefits
              benefits_text={props.data?.benefits_text}
            />
          ) : null
        }
        modules={
          <TariffCardContent.Modules modules_text={props.data?.modules_text} />
        }
        actionButton={
          <TariffCardContent.ActionButton
            onClick={() => props.onSelect(props.data)}
            disabled={props.disabled}
            label={
              <>
                {props.current ? (
                  <span className="disabled">{t("subscribed")}</span>
                ) : (
                  <>
                    <span className="active">{t("select")}</span>
                    <span className="disabled">{t("selected")}</span>
                  </>
                )}
              </>
            }
          />
        }
      />
    </TariffCardShell>
  )
}

TariffCard.propTypes = {
  data: PropTypes.object,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  current: PropTypes.bool,
  selected: PropTypes.bool,
  isAnnual: PropTypes.bool,
  currency: PropTypes.string,
}
