import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import { Nav } from "../Nav"
import { PageHeader } from "../../../../../../components"
import { usePanelTopBarContext } from "../../../../../../contexts"

export const Header = () => {
  const { t } = useTranslation()

  const { options } = usePanelTopBarContext()

  return (
    <div className={"panel-topbar"}>
      <PageHeader title={t("title.sales_analytics")} />

      <div className={"panel-topbar__nav mb-4"}>
        <div className={"panel-topbar__menu"}>
          <Nav />
        </div>

        {options.actions && (
          <div className="panel-topbar__actions">{options.actions}</div>
        )}
      </div>
    </div>
  )
}

Header.propTypes = {
  topBarOptions: PropTypes.object,
}
