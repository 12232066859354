import React, { useEffect, useState, useMemo } from "react"
import { connect, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Col, Row } from "reactstrap"
import classNames from "classnames"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import _ from "lodash"

import { Tariff } from "./Tariff"
import { AiTariff } from "./AiTariff"
import { TariffPeriodSwitch } from "./TariffPeriodSwitch"
import { TariffCurrencyDropdown } from "./TariffCurrencyDropdown"
import {
  BILLING_SUBSCRIPTION_TARIFF_PERIOD_MONTHLY,
  BILLING_SUBSCRIPTION_TARIFF_PERIOD_ANNUALLY,
  BILLING_SUBSCRIPTION_STATUS_ACTIVE,
  BILLING_SUBSCRIPTION_STATUS_TRIALING,
} from "consts"
import { billingHelper, billingSubscriptionHelper } from "helpers"
import { Button } from "components"
import { SubscriptionSwap } from "../SubscriptionComponents"
import { billingTariffList } from "store/actions"

import * as HOC from "../../../../HOC"

const DataShower = HOC.withDataShower()

const billingCurrency = billingHelper.currency.getCurrency().value

const SubscriptionTariffsContainerComponent = props => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const initialPeriodSwitch = useMemo(() => {
    if (props.subscription?.tariff) {
      return billingSubscriptionHelper.period.getConfig(
        props.subscription.tariff.period
      ).value
    }
    return BILLING_SUBSCRIPTION_TARIFF_PERIOD_ANNUALLY
  }, [])

  const [period, setPeriod] = useState(initialPeriodSwitch)
  const [selectedTariff, setSelectedTariff] = useState(null)
  const [currency, setCurrency] = useState(billingCurrency)

  useEffect(() => {
    dispatch(billingTariffList.getList({}))

    return () => {
      dispatch(billingTariffList.cleanState())
    }
  }, [])

  const onPeriodChange = value => {
    setPeriod(value)

    // reset selected tariff
    setSelectedTariff(null)
  }

  const onTariffSelect = value => {
    setSelectedTariff(value)
  }

  const onPay = () => {
    if (selectedTariff) {
      let price = _.find(selectedTariff?.prices, { currency: billingCurrency })
      history.push("/billing/pay", {
        paymentInfo: {
          price: price?.total,
          tariff: selectedTariff,
        },
      })
    }
  }

  const monthlyTariffs = useMemo(() => {
    if (props.list) {
      return props.list.filter(
        tariff => tariff.period === BILLING_SUBSCRIPTION_TARIFF_PERIOD_MONTHLY
      )
    }
    return []
  }, [props.list])

  const annuallyTariffs = useMemo(() => {
    if (props.list) {
      return props.list.filter(
        tariff => tariff.period === BILLING_SUBSCRIPTION_TARIFF_PERIOD_ANNUALLY
      )
    }
    return []
  }, [props.list])

  return (
    <Row>
      <Col lg="12">
        <DataShower
          isLoading={props.listLoading}
          isFailed={props.listError}
          error={props.listError}
          customLoaderClass={"mt-5"}
        >
          {props.list && (
            <>
              <TariffPeriodSwitch value={period} onChange={onPeriodChange} />

              <div className={"d-flex justify-content-center mt-4 mb-4"}>
                <TariffCurrencyDropdown
                  value={currency}
                  onChange={value => setCurrency(value)}
                />
              </div>

              <div
                className={classNames("pricing-block__panel", {
                  "pricing-block__panel_active":
                    period === BILLING_SUBSCRIPTION_TARIFF_PERIOD_MONTHLY,
                  "d-none":
                    period !== BILLING_SUBSCRIPTION_TARIFF_PERIOD_MONTHLY,
                })}
              >
                <div className="pricing-block__list">
                  <>
                    {monthlyTariffs.map((item, index) => (
                      <Tariff
                        key={"tariff-" + index}
                        data={item}
                        onSelect={onTariffSelect}
                        selectedTariff={selectedTariff}
                        currency={currency}
                      />
                    ))}

                    <AiTariff />
                  </>
                </div>
              </div>

              <div
                className={classNames("pricing-block__panel", {
                  "pricing-block__panel_active":
                    period === BILLING_SUBSCRIPTION_TARIFF_PERIOD_ANNUALLY,
                  "d-none":
                    period !== BILLING_SUBSCRIPTION_TARIFF_PERIOD_ANNUALLY,
                })}
              >
                <div className="pricing-block__list">
                  <>
                    {annuallyTariffs.map((item, index) => (
                      <Tariff
                        key={"tariff-" + index}
                        data={item}
                        onSelect={onTariffSelect}
                        selectedTariff={selectedTariff}
                        currency={currency}
                      />
                    ))}

                    <AiTariff />
                  </>
                </div>
              </div>

              <div className="text-center mb-4">
                {t("subscription.tariff.currency.alert", {
                  currency: billingHelper.currency.getCurrency().title,
                })}
              </div>

              <div className="text-center mb-4 mt-4">
                {!props.subscription ||
                props.subscription?.status ===
                  BILLING_SUBSCRIPTION_STATUS_TRIALING ? (
                  <Button
                    type="button"
                    size={"btn-lg"}
                    disabled={!selectedTariff}
                    onClick={onPay}
                    title={t("pay")}
                  />
                ) : props.subscription?.status ===
                  BILLING_SUBSCRIPTION_STATUS_ACTIVE ? (
                  <SubscriptionSwap tariff={selectedTariff} />
                ) : null}
              </div>
            </>
          )}
        </DataShower>
      </Col>
    </Row>
  )
}

SubscriptionTariffsContainerComponent.propTypes = {
  listLoading: PropTypes.bool,
  listError: PropTypes.any,
  list: PropTypes.array,
  subscription: PropTypes.object,
}

const mapStateToProps = state => {
  const { list, listLoading, listError } = state.billing.tariff.list
  return {
    listLoading,
    listError,
    list,
    subscription: state.user.profile?.data?.subscription,
  }
}

export const SubscriptionTariffsContainer = connect(mapStateToProps)(
  SubscriptionTariffsContainerComponent
)
