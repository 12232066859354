import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { TariffCardShell, TariffCardContent } from "../TariffCardShell"
import { EXTERNAL_LINK } from "consts"
import logo from "assets/images/Giulia-CRM-Brand-Color-AI.png"

export const AiTariff = () => {
  const { t } = useTranslation()

  const modulesText = useMemo(() => {
    return `<ul><li>${t("new")}!</li></ul>`
  }, [])

  return (
    <TariffCardShell
      className={classNames("", {
        large: true,
      })}
    >
      <TariffCardContent
        overhead={
          <TariffCardContent.Overhead name={t("tariff.ai_chat.label")} />
        }
        modules={<TariffCardContent.Modules modules_text={modulesText} />}
      />

      <TariffCardContent.ContentBox>
        <div className={"text-center"}>
          <img src={logo} width={120} alt={'logo'} />
        </div>
      </TariffCardContent.ContentBox>

      <TariffCardContent.ContentBox>
        <div className={"text-center"}>
          <a
            href={EXTERNAL_LINK.REQUEST_DEMO}
            target={"_blank"}
            rel="noreferrer"
            className={"text-primary font-size-16"}
          >
            {t("contact_us_for_demo")}
          </a>
        </div>
      </TariffCardContent.ContentBox>
    </TariffCardShell>
  )
}

AiTariff.propTypes = {}
